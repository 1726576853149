@import "variables";
@import "vendor/bootstrap";
@import "vendor/jquery.ui";

*{
	outline: none !important;
}

body a {
	color: theme-color(secondary);
}

#prestashop_profiling {
	position:absolute;
	left:0;
	top:0;
	z-index:1000;
	background:theme-color(white);
}

body,
html{
	position: relative;
}

a:hover,
a:focus{
	text-decoration: none !important;
}

.alert{
	border-radius:0 !important; 
	border: none !important;
}
.form-control{
	border-radius:2px !important;
	box-shadow:none !important;
}
.form-control:hover,
.form-control:focus{
	border-color: theme-color(black) !important;
}

.form-error input,
.form-error textarea{
	border: theme-color(red) solid 1px !important;
}


/*************** même hauteur colonne ******************/
body.show-left-column .main-content {
    overflow: hidden; 
}

body.show-left-column .main-content #left_column,
body.show-left-column .main-content #center_column{
    margin-bottom: -2000vh;
    padding-bottom: 2000vh;
}

/******* format margin ****************/
.noMargin{
	margin:0 !important;
}
.noMarginTop{
	margin-top:0 !important;
}
.noMarginBottom{
	margin-bottom:0 !important;
}
.marginTop{
	margin-top:15px !important;
}
.marginTop2x{
	margin-top:30px !important;
}
.marginTop4x{
	margin-top:60px !important;
}
.marginBottom{
	margin-bottom:15px !important;
}
.marginBottom2x{
	margin-bottom:30px !important;
}
.marginBottom4x{
	margin-bottom:60px !important;
}
.noPadding{
	padding:0;
}
.noPaddingTop{
	padding-top:0;
}
.noPaddingBottom{
	padding-bottom:0;
}
.paddingTop{
	padding-top:15px;
}
.paddingTop2x{
	padding-top:30px;
}
.paddingTop4x{
	padding-top:60px;
}
.paddingBottom{
	padding-bottom:15px;
}
.paddingBottom2x{
	padding-bottom:30px;
}
.paddingBottom4x{
	padding-bottom:60px;
}
@media(max-width:768px){
	.noPaddingTopMobile{
		padding-top:0 !important;
	}
}
/************* format txt ************************/
.text-justify{
	text-align:justify;
}
.cap{
	text-transform:uppercase;
}
.bold{
	font-weight:bold;
}
.noBold{
	font-weight:normal !important;
}
.transition-linear{
	-moz-transition:all linear .2s;
	-ms-transition:all linear .2s;
	-o-transition:all linear .2s;
	-webkit-transition:all linear .2s;
	transition:all linear .2s;
}
.transition-ease{
	-moz-transition:all ease .3s;
	-ms-transition:all ease .3s;
	-o-transition:all ease .3s;
	-webkit-transition:all ease .3s;
	transition:all ease .3s;
}

.text-noir{
	color: theme-color(black) !important;
}
.text-blanc{
	color: theme-color(white) !important;
}
.text-gris{
	color: lighten(theme-color(black),30%) !important;
}
.text-bleu{
	color: theme-color(secondary) !important;
}

.title{
	color: theme-color(black);
	font-size: 24px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
.title h1,
.title .h1,
.title h2,
.title .h2,
.title h3,
.title .h3{
	font-size: 24px;
	margin-top: 0;
	padding-top: 0;
}

@media(max-width:768px){
	.title h1,
	.title .h1,
	.title h2,
	.title .h2,
	.title h3,
	.title .h3{
		font-size: 20px;
	}
}

.title:after{
	content: "";
	display: block;
	border: theme-color(secondary) solid 2px;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 170px;
}

.unvisible{
	display: none;
}

/******************************* nav-side ********************************/
.content-side{
	width:16.6667%;
	position: fixed;
	z-index: 500;
	top: 0;
	left: 0;
	height: 100vh;
	background: rgba(0,0,0,1);
}

@media(max-height:620px){
	.content-side{
		overflow-x: hidden;
		overflow-y:scroll;
	}
}
	
@media(min-width:992px){
	.content-side.open{
		width: 100%;
		background: rgba(0,0,0,0.6);
	}
}
.nav-side{
	width: 100%;
	float: none !important;
	background: theme-color(black);
	min-height: 100% !important;
	position: absolute !important;
	padding: 0 !important;
	top: 0;
	left: 0;
	z-index: 200;
}
@media(min-width:992px){
	.content-side.open .nav-side{
		width:16.6667%;	
	}
}

@media(max-width:991px){
	.adtm_sub{
		display: none !important;
	}
	.content-side{
		width: 100vw;
		left: -100vw;
		-moz-transition:all linear .2s;
		-ms-transition:all linear .2s;
		-o-transition:all linear .2s;
		-webkit-transition:all linear .2s;
		transition:all linear .2s;
	}
	.content-side.open-mobile{
		left:0;
		background: rgba(0,0,0,0.6);
	}
	.nav-side{
		width: 80vw;
	}
}

@media(min-width:991px){
	.submenu-open{
		overflow:hidden;
	}
}

.nav-side .bloc-title{
	background: theme-color(primary);
	height: 45px;
	font-size: 18px;
	line-height: 0.9;
	text-transform: uppercase;
	color: theme-color(white);
	padding: 15px;
	padding-right: 10px;
	font-family: filson-pro, sans-serif;
    font-weight: 500;
}


@media(min-width:992px) and (max-width:1060px){
	.nav-side .bloc-title{
		font-size: 16px;
	}
}

.nav-side .bloc-title div{
	display: inline-block;
	width: 30px;
	float: left;
}
.nav-side .bloc-title span{
	width: 23px;
	height: 2px;
	background: theme-color(white);
	margin-bottom: 5px;
	display: block;
}

/******************************* .nav ***********************************/
#header .nav{
	background: theme-color(black);
	vertical-align: middle;	
	line-height: 45px;
}
#header .nav .pull-left,
#header .nav .pull-right{
	/*line-height: 1 !important;*/
	color: theme-color(white);
	font-family: filson-pro, sans-serif;
    font-weight: 300;
	vertical-align: middle;	
	line-height: 38px;
	height: 45px;
}
#header .nav .pull-left *,
#header .nav .pull-right *{
	display: inline;
	vertical-align: middle;
	line-height: 0.8;
}
#header .nav a{
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	font-size: 18px;
	color: theme-color(white);
}
#header .nav a:before{
	content: "\e908";
	line-height: 0.8;
	font-family: 'icomoon';
	font-size: 26px;
	display: inline-block;
	margin:0 8px;
	transform: translate(0,4px);
	-webkit-transform: translate(0,4px);
}
.toggle-nav{
	background: theme-color(primary);
	padding: 10px;
	padding-top: 17px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 200;
}

@media(min-width:768px) and (max-width:991px){
	.toggle-nav{
		top: 45px;	
	}
}

@media(max-width:991px){
	.toggle-nav span:first-child{
		margin-top: -5px;
	}
	.toggle-nav:after{
		content: "Menu";
		display: block;
		font-size: 10px;
		transform: translateY(-5px);
		-webkit-transform: translateY(-5px);
	}
}

.toggle-nav span{
	width: 100%;
	height: 2px;
	background: theme-color(black);
	margin-bottom: 5px;
	display: block;
}


.upline,
.upline h1,
.upline .h1{
	margin: 0;
	padding: 0;
	color: theme-color(white);
	font-size: 12px;
	text-transform: uppercase;
}

/******************************* .post-header ***************************/
.post-header{
	background: theme-color(white);
	border-bottom: #ddd solid 1px;
	padding-top: 15px;
	padding-bottom: 15px;
}

#header_logo a{
	display: inline-block;
}

@media(max-width:991px){
	.post-header{
		height: 50px;
		padding: 0;
		padding-left: 44px;
	}
	#header_logo{
		width: 90px;
		float: left;
	}
	#header_logo{
		transform: translate(0,10px);
		-webkit-transform: translate(0,10px);
	}
}

/******************************* reassurance-top ************************/
.reassurance-top{
	background: theme-color(secondary);
	padding:15px;
}
.reassurance-top ul{
	list-style-type: none;
	padding-left: 0;
	margin: 0;
	color:theme-color(white);
}
.reassurance-top ul.pull-left li{
	float: left;
}
.reassurance-top ul.pull-left li:first-child{
	padding-right: 15px;
	margin-right: 15px;
	border-right:lighten(theme-color(secondary),20%) solid 1px; 
}
.reassurance-top a{
	color:theme-color(white);
	text-transform: uppercase;
	font-size: 16px;
	font-family: filson-pro, sans-serif;
    font-weight: 500;
}
.reassurance-top a span{
	font-size: 14px;
	text-transform: none;
	font-family: filson-pro, sans-serif;
    font-weight: 300;
}
.reassurance-top .pull-left{
	width: 40%;
}

.reassurance-top .pull-right{
	text-align: right;
	width: 60%;
}

@media(max-width:991px){
	.reassurance-top .pull-right a{
		font-size: 14px;
		line-height: 1.2;
	}
	.reassurance-top span{
		display: block !important;
		font-size: 11px !important;
	}
}
/************************************* page ****************************/
#page{
	background: lighten(theme-color(black), 87%);
}
.tab-content{
	padding-left: 15px;
	padding-right: 15px;
}
.main-content{
	position: relative;
	padding-bottom: 60px;
	min-height: 450px;
}

.breadcrumb{
	background: none !important;
	color: lighten(theme-color(black),30%);
	font-size: 13px;
	font-family: filson-pro, sans-serif;
    font-weight: 300;
	padding: 0 !important;
}
.breadcrumb a{
	color: lighten(theme-color(black),30%);
}
.breadcrumb strong{
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	font-weight: normal;
}
.navigation-pipe{
	display: inline-block;
	transform: translate(0,2px);
	-webkit-transform: translate(0,1px);
	font-size: 12px;
	padding-left: 3px;
	padding-right: 3px;
}

.htmlcontent-home{
	padding-left: 0;
	list-style-type: none;
}

#left_column{
	background: theme-color(white);
	padding: 20px;
}
#left_column .title_block{
	margin-bottom: 18px;
	font-size: 18px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	line-height: 1.2;
	padding-bottom: 10px;
}
#left_column .block:not(:last-child):after{
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background: lighten(theme-color(black),70%);
	margin-top: 10px;
	margin-bottom: 10px;
}

#center_column{
	padding: 30px;
}
@media(max-width:768px){
	#center_column{
		padding:15px;
	}	
}

@media(max-width:991px){
	#left_column{
		display: none;
	}
	#left_column.open{
		display: block;
	}
	.btn-layered{
		position: absolute;
		top: 0;
		right: 0;
		text-align: center;
		width: 40px;
		height: 40px;
		background: lighten(theme-color(black),90%);
		line-height: 40px;
		font-size: 18px;
		cursor: pointer;
	}
}

/******************************* blockmanufacturer *********************/
#manufacturers_block_left{
	padding-top:60px;
}
#manufacturers_block_left .list-block{
	padding-left:50px;
	padding-right:50px;
}
#manufacturers_block_left .bx-wrapper{
	margin: 0 auto !important;
}
#manufacturers_block_left li > span{
	display:block;
	width:110px;
	height:55px;
	background-size:contain !important;
	background-blend-mode:multiply;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
	text-indent:-9999px;
	opacity:0.6;
	-moz-transition:all ease .3s;
	-ms-transition:all ease .3s;
	-o-transition:all ease .3s;
	-webkit-transition:all ease .3s;
	transition:all ease .3s;
}
#manufacturers_block_left li > span:hover{
	opacity:1;
	-webkit-filter: grayscale(0);
    filter: grayscale(0);
}
#manufacturers_block_left li span span{
	display: none;
}
#manufacturers_block_left .bx-prev{
	left:-50px;
}
#manufacturers_block_left .bx-next{
	right:-50px;
}

#manufacturers_block_left .bx-wrapper .bx-controls-direction a:before{
	font-family: 'icomoon' !important;
	color:theme-color(black);
}
#manufacturers_block_left .bx-wrapper .bx-controls-direction a{
	top:calc(50% - 10px) !important;
	margin-top:0 !important;
}
#manufacturers_block_left .bx-prev:before{
	content: "\e902";
}
#manufacturers_block_left .bx-next:before{
	content: "\e903";
}


/********************************* pagination ************************/
.pagination,
.pagination *{
	background: none !important;
	border: none !important;
	padding: 0 !important;
}
.pagination li{
	padding: 0 8px !important;
	display: inline-block !important;
}
.pagination{
	font-size: 18px;
}
.pagination a span{
	color: theme-color(black) !important;
}
.pagination .active span{
	color: theme-color(secondary) !important;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
.pagination .truncate span span{
	color: theme-color(black);
}


/***************************** footer ********************************/
/*#footer{
	padding-top: 60px;
}*/
.reassurance{
	background: lighten(theme-color(black),20%);
	color: lighten(theme-color(black),60%);
	padding: 0 15px;
}
@media(max-width:990px){
	.reassurance{
		padding:20px;
	}
	#footer .reassurance .col-xs-6{
		padding-bottom: 20px;
	}
	#footer .reassurance .col-xs-6,
	#footer .reassurance .col-xs-6 .h4{
		font-size: 12px;
	}
	.reassurance .col-xs-12{
		text-align: center;
		font-size: 13px;
	}
	#footer .reassurance .col-xs-12 .h4,
	#footer .reassurance .col-xs-12 .h4 a{
		font-size: 24px;
	}
	#footer .reassurance small,
	#footer .reassurance i{
		display: none;
	}
}
@media(min-width:991px){
	.reassurance .col-sm-4{
		padding:20px 30px !important;
	}
	.reassurance .col-sm-4:not(:last-child):before{
		content: "";
		display: block;
		width: 1px;
		height: 55%;
		background: lighten(theme-color(black),35%);
		position: absolute;
		top: -2px;
		right: 0;
		-ms-transform: rotate(-25deg);
		-webkit-transform: rotate(-25deg);
		transform: rotate(-25deg);
	}
	.reassurance .col-sm-4:not(:last-child):after{
		content: "";
		display: block;
		width: 1px;
		height: 55%;
		background: lighten(theme-color(black),35%);
		position: absolute;
		bottom: -2px;
		right: 0;
		-ms-transform: rotate(25deg);
		-webkit-transform: rotate(25deg);
		transform: rotate(25deg);
	}
}
.reassurance i{
	font-size: 16px;
	float: left;
	margin-right: 10px;
	width: 30px;
	height: 30px;
	border: lighten(theme-color(black),60%) solid 1px;
	border-radius:100%;
	text-align: center;
	line-height: 30px;
}
#footer{
	color: lighten(theme-color(black),90%);
}
.col-footer{
	background:theme-color(black);
	padding: 30px 15px;
}
#footer a{
	color: lighten(theme-color(black),90%);
}
#footer .h4{
	margin-bottom: 20px;
}
#footer .h4,
#footer .h4 a{
	font-size: 18px;
	text-transform: uppercase;
	color: theme-color(white);
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	margin-top: 0;
	padding-top: 0;
}

@media(max-width:768px){
	.col-footer .h4{
		cursor: pointer;
	}
	.col-footer{
		padding:20px 5px;
	}
	.col-footer .h4:after{
		content: "\f067";
		font-family:'FontAwesome';
		float: right;
	}
	.toggle-footer{
		display: none;
	}
	.active + .toggle-footer{
		display: block;
		padding-bottom: 15px;
	}
}

#footer ul{
	padding-left: 0;
	list-style-type: none;
	line-height: 28px;
}
#social_block li{
	display: inline-block;
	margin-right: 5px;
}
#social_block i{
	color: theme-color(black);
	background: theme-color(white);
	font-size: 20px;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	border-radius:100%;
}
#social_block span{
	display: none;
}

@media(max-width:768px){
	#footer .col-xs-12{
		width: 100% !important;
	}
	#social_block{
		background: lighten(theme-color(black),90%);
		margin-left: -20px;
		margin-right: -20px;
		padding: 20px;
		text-align: center;
	}
	.col-footer{
		padding-bottom: 0 !important;
	}
	#social_block .h4:after{
		display: none;
	}
	#social_block .h4{
		margin-bottom: 10px;
	}
	#social_block .h4,
	#social_block a{
		color: theme-color(black) !important;
	}
	#social_block ul{
		margin-bottom: 0;
	}
	#social_block i{
		background: theme-color(black);
		color: lighten(theme-color(black),90%);
	}
}

.copyright{
	background: theme-color(black);
	font-size: 12px;
	color: lighten(theme-color(black),60%);
	padding: 15px 30px;
	text-align: right;
}
#footer .copyright a{
	color: lighten(theme-color(black),60%);
}

/******************************* tunnel achat *****************************/
.cart_last_product{
	background: theme-color(white);
	padding: 15px;
	margin-bottom: 30px;
}
.cart_last_product_header{
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 15px;
}
.cart_last_product_img{
	display: block;
	margin-bottom: 15px;
}
.cart_last_product_content a{
	color: theme-color(black);
    display: inline-block;
    font-family: filson-pro, sans-serif;
    font-weight: 700;
    font-size: 16px;
}
.cart_last_product_content p{
	margin-bottom: 0;
}
#order-opc table{
	background: theme-color(white);
	border-width: 3px;
}

.cart_product{
	text-align: center;
}
.cart_product img{
	display: inline-block !important;
}

.cart-img{
	display: block;
	position: relative;
}
.cart_product .img_manu{
	height: 10px;
	position: absolute;
	bottom: 8px;
	right: 8px;
}

.cart_ref{
	color: lighten(theme-color(black),30%);
	font-size: 14px;
}
.cart_description .product-name a{
	font-size: 16px;
	color: theme-color(black);
}


.step-num span{
	margin-right: 3px;
	transform: translate(0,-3px);
	-webkit-transform: translate(0,-3px);
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius:100%; 
	font-family: filson-pro, sans-serif;
    font-weight: 700;
    font-size: 16px;
	background: theme-color(primary);
	text-align: center;
}
.delivery_option table,
.delivery_option table *{
	vertical-align: middle !important;
}
.delivery_option .delivery_option_radio{
	width: 20px;
	text-align: center;
}
.delivery_option_logo{
	width: 75px;
}
.delivery_option_logo img{
	max-width: 100%;
	height: auto;
	border: none !important;
}
.delivery_option_price{
	width: 150px;
	text-align: right;
	padding: 20px;
}
.cart_quantity_input{
	text-align: center;
}
.button-plus,
.button-minus{
	color: lighten(theme-color(black),30%);
}

#cart_summary .price{
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
#cart_summary .total_price_container,
#cart_summary #total_price_container{
	font-size: 24px;
}
#cart_summary #total_price_container{
	color: theme-color(secondary);
}

#cart_summary ul{
	list-style-type: none;
	padding-left: 0;
}

#cart_summary ul .price-percent-reduction{
	display: none !important;
}

#cart_summary td[valign=middle]{
	vertical-align: middle !important;
}
.cart_quantity_delete{
	font-size: 18px;
	color: lighten(theme-color(black),30%);
}

.delivery_options table{
	margin-bottom: 20px;
}
.form_info{
	color: lighten(theme-color(black),30%);
	font-style: italic;
	padding-top: 5px;
	display: inline-block;
	font-size: 12px;
}


@media(max-width:560px){
	.delivery_option table,
	.delivery_option table *{
		display: inline-block;
		text-align: center;
		width: 100%;
	}
	.delivery_option table tr,
	.delivery_option table td{
		border: none !important;
	}
	.delivery_option table img{
		width: 75px;
		height: 75px;
	}
	.delivery_option td:nth-child(3){
		display: none !important;
	}
	#uniform-days span,
	#uniform-days select,
	#uniform-months span,
	#uniform-months select,
	#uniform-years span,
	#uniform-years select{
		width: 50px !important;
	}
	#uniform-days,
	#uniform-months,
	#uniform-years{
		width: 60px !important;
	}
}

div.payment_module div.atos:after{
	display: none !important;
}

div.payment_module,
p.payment_module,
p.payment_module{
	padding: 15px !important;
	background: theme-color(white) !important;
	border: none !important;
	border-radius: 0 !important;
	margin-bottom: 30px !important;
}
div.payment_module *,
p.payment_module *,
p.payment_module * {
	background: theme-color(white) !important;
	border: none !important;
	border-radius: 0 !important;
	padding: 0 !important;
	margin-bottom: 0 !important;
	font-size: 0 !important;
}
p.payment_module.paypal a{
	padding: 0 !important;
}

/********************** voucher ****************************/
#cart_voucher{
	padding: 20px;
}
#voucher h4{
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
#voucher .btn,
.button-blue{
	background: theme-color(secondary);
	border: theme-color(secondary) 1px solid !important;
	color: theme-color(white);
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	-moz-transition:all ease .3s;
	-ms-transition:all ease .3s;
	-o-transition:all ease .3s;
	-webkit-transition:all ease .3s;
	transition:all ease .3s;
	font-size: 14px;
	padding: 6px 12px;
	line-height: 1.42857;
	cursor: pointer;
	border-radius:5px;
	display: inline-block;
	text-decoration: none !important;
}
#voucher .btn:hover,
.button-blue:hover{
	background: theme-color(primary);
	border-color: theme-color(primary) !important;
	color: theme-color(white);
}

.button-gris{
	display: inline-block;
	background: lighten(theme-color(black),30%);
	border: lighten(theme-color(black),30%) 1px solid;
	color: theme-color(white);
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	-moz-transition:all ease .3s;
	-ms-transition:all ease .3s;
	-o-transition:all ease .3s;
	-webkit-transition:all ease .3s;
	transition:all ease .3s;
	font-size: 14px;
	padding: 6px 12px;
	line-height: 1.42857;
	cursor: pointer;
	border-radius:5px; 
}
.button-gris:hover{
	background: theme-color(black);
	color: theme-color(white);
}

.cart_discount{
	background: #eee;
	border-left:theme-color(primary) solid 3px; 
}
.price_discount_delete{
	color: theme-color(black);
}
/************************* box ********************************/
.box{
	background: theme-color(white);
	padding: 20px;
	margin-bottom: 30px;
	list-style-type: none;
}
.box ul{
	list-style-type: none;
}
.box h3,
.box .h3,
.box h4,
.box .h4{
	margin-top:0;
	font-size: 18px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
.cgv-box{
	background: lighten(theme-color(black),90%);
}
.cgv-box p{
	margin-bottom: 0;
}


/*******************************panier responsive **********************/
@media(max-width:768px){
	#order-detail-content #cart_summary table, #order-detail-content #cart_summary thead, #order-detail-content #cart_summary tbody, #order-detail-content #cart_summary th, #order-detail-content #cart_summary td, #order-detail-content #cart_summary tr {
    display: block; }
  #order-detail-content #cart_summary thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  #order-detail-content #cart_summary tr {
    border-bottom: 1px solid lighten(theme-color(black),70%);
    padding-bottom: 7px;
    overflow: hidden;
    position: relative; }
  #order-detail-content #cart_summary td {
    border: none;
    position: relative;
    float: left;
    white-space: normal;
    padding: 7px 20px 7px 20px; }
    #order-detail-content #cart_summary td.cart_product {
      text-align: left; }
    #order-detail-content #cart_summary td.cart_avail {
      display: none; }
    #order-detail-content #cart_summary td.cart_unit {
      width: 33%;
      text-align: right;
      min-height: 70px;
      clear: both; }
      #order-detail-content #cart_summary td.cart_unit .price {
        text-align: right; }
    #order-detail-content #cart_summary td.cart_quantity {
      width: 33%;
      min-height: 70px;
      border-left: 1px solid lighten(theme-color(black),70%);
      border-right: 1px solid lighten(theme-color(black),70%) ; }
      #order-detail-content #cart_summary td.cart_quantity div {
        display: block; }
      #order-detail-content #cart_summary td.cart_quantity > .form-control {
        display: inline; }
      #order-detail-content #cart_summary td.cart_quantity > .cart_quantity_button a {
        margin-right: 0px;
        float: none; }
    #order-detail-content #cart_summary td.cart_total {
      width: 33%;
      min-height: 70px;
      text-align: right; }
    #order-detail-content #cart_summary td.cart_delete {
      position: absolute;
      right: 20px;
      top: 7px; }
      #order-detail-content #cart_summary td.cart_delete:before {
        content: '' !important; }
    #order-detail-content #cart_summary td div {
      display: inline; }
  #order-detail-content #cart_summary td:before {
    content: attr(data-title);
    font-weight: bold;
    display: block; }
  #order-detail-content #cart_summary tfoot td {
    float: none;
    width: 100%; }
    #order-detail-content #cart_summary tfoot td:before {
      display: inline; }
  #order-detail-content #cart_summary tfoot tr .text-right, #order-detail-content #cart_summary tfoot tr tbody td.cart_unit, #order-detail-content #cart_summary tbody tfoot tr td.cart_unit, #order-detail-content #cart_summary tfoot tr tbody td.cart_total, #order-detail-content #cart_summary tbody tfoot tr td.cart_total, #order-detail-content #cart_summary tfoot tr .price {
    display: block;
    float: left;
    width: 50%; }
	
	#voucher button{
		margin-top: 15px;
	}
}


/************************* compte **************************/
.footer_links{
	list-style-type: none;
	padding-left: 0;
}

@media(max-width:400px){
	.footer_links a{
		font-size: 24px;
		line-height: 24px;
	}
	.footer_links span span{
		display: none;
	}
}

.info-account{
	background: theme-color(white);
	padding: 20px;
	margin-bottom: 30px;
	border-left:theme-color(primary) solid 3px; 
}


body#history #center_column table,
body#stores #center_column table,
body#module-pdfquotation-accountpdfquotation #center_column table,
body#quotation-list #center_column table,
body#order-follow #center_column table,
body#discount #center_column table,
body#order-slip #center_column table{
	background: theme-color(white);
	border-width: 3px;
}



/******************************* blockmyaccount *****************************/
.post-header .myaccount-content{
	display: none !important;
	position: absolute;
	top: 0;
	left: -20%;
	width: 120%;
	height: 100%;
	background: rgba(0,0,0,0.6);
	z-index: 500;
}
#myaccount-column{
	position: absolute;
	background: theme-color(white);
	padding: 30px;
	top: 20vh;
	left: 50%;
	width: 500px;
	transform: translate(-50%,0);
	-webkit-transform: translate(-50%,0);
}


/******************** alert *****************************/
.alert{
	background: theme-color(white) !important;
	padding: 20px !important;
	margin-bottom: 30px !important;
	border: none !important;
	border-left:theme-color(black) solid 3px !important;
	color: theme-color(black) !important;
	border-radius: 0 !important;
}
.alert a{
	color: theme-color(black) !important;
	display: inline-block;
	padding-bottom: 3px;
	border-bottom: lighten(theme-color(black),60%) solid 1px;
}
.alert p,
.alert ol,
.alert ul{
	margin-bottom: 0;
	padding-left: 15px;
	list-style-position: inside;
}
.alert-success{
	border-left-color:theme-color(green) !important; 
}
.alert-info{
	border-left-color:theme-color(secondary)  !important; 
}
.alert-warning{
	border-left-color:theme-color(primary) !important; 
}
.alert-danger{
	border-left-color:theme-color(red) !important; 
}

/******************** blockinfocms ***************/
.block-cms-info{
	color: theme-color(white);
	background:lighten(theme-color(black),20%);
	padding:20px;
	margin-bottom: 30px;
}
.block-cms-info h3{
	font-size: 18px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	margin-top: 0;
}
.block-cms-info a{
	color: theme-color(white);
}

/************************* livraison gratuite ************************/
.livraison-gratuite{
	width: 70px;
	position: absolute;
	top: 75px;
	right: 10px;
	z-index: 20;
	background: theme-color(primary);
	color: theme-color(white);
	font-size: 12px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	padding: 4px;
	text-align: center;
	line-height: 1;
	-moz-box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
	-webkit-box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
	-o-box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
	box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
}
.livraison-gratuite:after{
	position: absolute;
	bottom: -5px;
	right: 0;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 0;
	border-color: theme-color(red) transparent transparent transparent;
}
.livraison-gratuite span{
	display: block;
	font-size: 7px;
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 500;
}