body {
    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
        background: theme-color(secondary);
        cursor: pointer;

        &:hover {
            background: darken(theme-color(secondary),5%);
        }
    }
}